/* Add this to your global CSS file */

.star-1,
.star-2,
.star-3,
.star-4,
.star-5,
.star-6 {
    color:whitesmoke;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
}

button .star-1 {
    color:whitesmoke;
  top: -80%;
  left: -30%;
  filter: drop-shadow(0 0 10px #fffdef);
  z-index: 2;
}

button .star-2 {
    color:whitesmoke;
  top: -25%;
  left: 10%;
  filter: drop-shadow(0 0 10px #fffdef);
  z-index: 2;
}

button .star-3 {
    color:whitesmoke;
  top: 55%;
  left: 25%;
  filter: drop-shadow(0 0 10px #fffdef);
  z-index: 2;
}

.button .star-4 {
  top: 30%;
  left: 80%;
  filter: drop-shadow(0 0 10px #fffdef);
  z-index: 2;
}

button .star-5 {
  top: 25%;
  left: 115%;
  filter: drop-shadow(0 0 10px #fffdef);
  z-index: 2;
}

button .star-6 {
  top: 5%;
  left: 60%;
  filter: drop-shadow(0 0 10px #fffdef);
  z-index: 2;
}

.fil0 {
  fill: #fffdef;
}


/* For Card */
.card {
    position: relative;
    /* width: 630px;
    height: 250px; */
    border-radius: 14px;
    z-index: 1111;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
    ;
  }
  
  .bg {
    position: absolute;
    top: 5px;
    left: 5px;
    /* width: 335px;
    height: 240px; */
    z-index: 2;
    background: rgba(255, 255, 255, .95);
    backdrop-filter: blur(24px);
    border-radius: 10px;
    overflow: hidden;
    outline: 2px solid white;
  }
  
  .blob {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 150px;
    border-radius: 50%;
    background-color: #ff0000;
    opacity: 1;
    filter: blur(12px);
    animation: blob-bounce 5s infinite ease;
  }
  
  @keyframes blob-bounce {
    0% {
      transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
  
    25% {
      transform: translate(-100%, -100%) translate3d(100%, 0, 0);
    }
  
    50% {
      transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
    }
  
    75% {
      transform: translate(-100%, -100%) translate3d(0, 100%, 0);
    }
  
    100% {
      transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
  }
  