

.circle{
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(#3ABEF9 ,#CB77F7);
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
}

.small{
  width: 100px;
  height: 100px;
  left: -100px;
  bottom: -0px;
}

.medium{
  width: 200px;
  height: 200px;
  left: -200px;
  bottom: -10px;
}

.large{
  width: 400px;
  height: 400px;
  left: -300px;
  bottom: -50px;
}

.xlarge{
  width: 600px;
  height: 600px;
  left: -400px;
  bottom: -200px;
}

.xxlarge{
  width: 800px;
  height: 800px;
  left: -500px;
  bottom: -300px;
}

.shade1{
  opacity: 0.2;
}
.shade2{
  opacity: 0.5;
}

.shade3{
  opacity: 0.7;
}

.shade4{
  opacity: 0.8;
}

.shade5{
  opacity: 0.9;
}

@keyframes ripple{
  0%{
    transform: scale(0.8);
  }
  
  50%{
    transform: scale(1.2);
  }
  
  100%{
    transform: scale(0.8);
  }
}
